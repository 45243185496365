import { useAppDispatch, useAppSelector } from "../../hooks";
import { addToAddOns, removeFromAddOns } from "../../slices/productSlice";
import { useEffect, useState } from "react";
import { PriceObject } from "../../types/Prices";
import environmentConstant from "../constants/environmentConstant";

const premiumProductId =
  process.env.REACT_APP_ENV === environmentConstant.PRODUCTION
    ? "prod_MT7hfnPQu8oaWZ"
    : "prod_MSgnpVHnTVtBuc";

const premiumSkuIds =
  process.env.REACT_APP_ENV === environmentConstant.PRODUCTION
    ? [
        "price_1On9MoA7w7Y5M9CKVPPJz6Wl",
        "price_1LkBS1A7w7Y5M9CKxx4rQ9Xn",
        "price_1LkBS1A7w7Y5M9CKPdcrJHiv",
      ]
    : [
        "price_1OmconA7w7Y5M9CKI0SXRQf4",
        "price_1LjlPSA7w7Y5M9CKrYRh9bpN",
        "price_1OmcpGA7w7Y5M9CKHQXHWuoi",
      ];

function containsPremiumSku(skus: PriceObject[]): boolean {
  return !!skus.find(sku => premiumSkuIds.includes(sku.id));
}

export function usePremiumPackage() {
  const { addOnSkus, selectedSku } = useAppSelector(state => state.products);
  const dispatch = useAppDispatch();
  const skus = selectedSku ? [selectedSku, ...addOnSkus] : addOnSkus;

  const [isPremium, setIsPremium] = useState(containsPremiumSku(skus));

  useEffect(() => {
    const skus = selectedSku ? [selectedSku, ...addOnSkus] : addOnSkus;
    setIsPremium(containsPremiumSku(skus));
  }, [setIsPremium, addOnSkus, selectedSku]);

  const { products } = useAppSelector(state => state.products);
  const premiumSku = products
    .find(product => product.id === premiumProductId)
    ?.skus.data.find(sku => premiumSkuIds.includes(sku.id));

  if (!premiumSku)
    throw new Error("Could not find SKU for premium package in Stripe");

  function set(setter: boolean) {
    if (!premiumSku) return;
    if (setter === true) {
      dispatch(addToAddOns({ addOn: premiumSku, repeatable: false }));
    } else {
      dispatch(removeFromAddOns({ addOn: premiumSku }));
    }
  }

  return {
    isPremium,
    premiumSku,
    setIsPremium: set,
  };
}
